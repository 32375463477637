@import '../../assets/scss/mixins.scss'; 

.container {
    @include wide-container;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sectors {
    @include wide-container;
    padding: 5rem 0;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 1200px) {
        flex-direction: column;
    }

}

.program {
    background-color: #042138;
    padding: 5rem 0;

    @media only screen and (max-width: 600px) {
        padding: 2rem 0;
    }

    h3 {
        font-size: 36px;
        color: white;
        line-height: 1;
        text-align: center;
    }

    .subtitle {
        color: #ffa53b;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 3px;
        font-size: 20px;
        margin-bottom: 1rem;
    }

    .fancy {
        font-weight: bold;
        font-size: 5vw;
        text-transform: uppercase;
        text-align: center;
        line-height: 1;
        @supports (background-clip: text) or (-webkit-background-clip: text) {
            background-image: 
                url("data:image/svg+xml,%3Csvg width='2250' height='900' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath fill='%2300A080' d='M0 0h2255v899H0z'/%3E%3Ccircle cx='366' cy='207' r='366' fill='%2312c990'/%3E%3Ccircle cx='1777.5' cy='318.5' r='477.5' fill='%2312c990'/%3E%3Ccircle cx='1215' cy='737' r='366' fill='%23008060'/%3E%3C/g%3E%3C/svg%3E%0A");
            background-size: 110% auto;
            background-position: center;
            color: transparent;
            -webkit-background-clip: text;
            background-clip: text;
        }
    }
}

.verticalTimeline {
    margin-bottom: 2rem;
    overflow: hidden;

    &_icon {
        background: #12c990;
        color: #000;
        box-shadow: none;
        width: 30px;
        height: 30px;
        left: 5px;
        top: 5px;
        
        @media only screen and (min-width: 1170px) {
            width: 30px !important;
            height: 30px !important;
            margin-left: -15px !important;
            top: 15px !important;
        }
    }

    &_element {
        font-size: 25px !important;
        letter-spacing: 2px;
        text-transform: capitalize;
        padding: 0.4em 0;
        margin: 0;

        @media only screen and (max-width: 1169px) {
            text-align: left !important;
            padding: 0.1em 0;
        }

        @media only screen and (max-width: 600px) {
            font-size: 18px !important;
        }
    }
}

.map {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 0;
    background: #f7faff;

    @media only screen and (max-width: 600px) {
        padding: 2rem 0;
    }

    h3 {
        font-size: 36px;
        line-height: 1;
        color: black;
    }

    img {
        max-width: 40%;

        @media screen and (max-width: 600px) {
            max-width: 100%;
        }
    }
}

.openStage {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 0;
    background: white;
    position: relative;

    @media only screen and (max-width: 600px) {
        padding: 2rem 0;
    }

    h3 {
        font-size: 36px;
        line-height: 1;
        color: black;
        text-align: center;

        img {
            width: 35px;
        }
    }

    p {
        text-align: center;
        @include wide-container;
    }
}

.contest {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 5rem;
    background: white;
    position: relative;

    @media only screen and (max-width: 600px) {
        padding: 2rem;
    }

    h3 {
        font-size: 36px;
        line-height: 1;
        color: black;
        text-align: center;

        img {
            width: 35px;
        }
    }

    p {
        font-weight: 300;
    }

    h4 {
        font-size: 20px;
    }
}
