$anim-dur: 3.2s;
$timing: linear;
$c1: #1565C0;
$c2: #B71C1C;
$c3: #f6c319;
$c4: #12c990;
$c5: #00BCD4;
$c6: #ffa53b;
$pos: -7.54em;

// Different margin for Firefox

.one.ff {
  margin-left: calc(-7.54em + -7.54em);
}

.two.ff {
  margin-left: calc(-2.54em + 5em + -7.54em);
}

.three.ff {
  margin-left: calc(2.46em + 10em + -7.54em);
}

.four.ff {
  margin-left: calc(15em + -7.54em + 7.46em);
}

.circle {
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.loader {
    width: 19em;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.circle {
    border-radius: 100%;
    position: absolute;
}

.circleBlue {
    margin-left: calc(0em + #{$pos});
    background: $c1;
    width: 3em;
    height: 3em;
    animation: scale $anim-dur $timing infinite;
  }
  
  .circleRed {
    background: $c2;
    margin-left: calc(5em + #{$pos});
    width: 3em;
    height: 3em;
    animation: circle-red-scale $anim-dur $timing infinite;
  }
  
  .circleYellow {
    margin-left: calc(0em + #{$pos});
    width: 2em;
    height: 2em;
    border: 1em solid $c3;
    animation: scale-out $anim-dur $timing infinite;
    opacity: 0;
    transform: scale(1, 1);
  }
  
  .circleYellow2 {
    margin-left: calc(0em + #{$pos});
    width: 2em;
    height: 2em;
    background: $c3;
    opacity: 0;
    animation: fade-in $anim-dur $timing infinite;
  }
  
  .circleYellowFill {
    background: $c3;
    margin-left: calc(5em + #{$pos});
    width: 2em;
    height: 2em;
    opacity: 0;
    animation: yellow-fill $anim-dur $timing infinite;
  }
  
  .circleYellowCenter {
    background: $c3;
    margin-left: calc(5em + #{$pos});
    width: 0.9em;
    height: 0.9em;
    opacity: 0;
    animation: yellow-center $anim-dur $timing infinite
  }
  
  .circleYellowBg {
    background: $c3;
    margin-left: calc(5em + #{$pos});
    width: 1em;
    height: 1em;
    animation: yellow-bg $anim-dur $timing infinite
  }
  
  .small {
    width: 0.38em;
    height: 0.38em;
    background: $c5;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -0.19em;
  }
  
  .small:first-child {
    transform: rotate(180deg) translate(1.65em) rotate(-180deg)
  }
  
  .small:nth-child(2) {
    width: 0.35em;
    height: 0.35em;
    transform: rotate(160deg) translate(1.65em) rotate(-160deg)
  }
  
  .small:nth-child(3) {
    width: 0.32em;
    height: 0.32em;
    transform: rotate(140deg) translate(1.65em) rotate(-140deg)
  }
  
  .small:nth-child(4) {
    width: 0.29em;
    height: 0.29em;
    transform: rotate(120deg) translate(1.65em) rotate(-120deg)
  }
  
  .small:nth-child(5) {
    width: 0.26em;
    height: 0.26em;
    transform: rotate(100deg) translate(1.65em) rotate(-100deg)
  }
  .small:nth-child(6) {
    width: 0.23em;
    height: 0.23em;
    transform: rotate(80deg) translate(1.65em) rotate(-80deg)
  }
  
  
  .arc {
    margin-left: calc(0em + #{$pos});
    width: 2em;
    height: 2em;
    border: 0.5em solid $c1;
    border-right-color: transparent;
    border-bottom-color: transparent;
    animation: arc-rotate $anim-dur $timing infinite;
    opacity: 0;
  }
  
  .smallWrap {
    margin-left: calc(#{$pos} + #{$pos});
    width: 4em;
    height: 4em;
    position: relative;
    animation: rotate $anim-dur $timing infinite;
    opacity: 0;
    transform: rotate(180deg);
  }
  
  .lineWrap {
    width: 4em;
    height: 4em;
    margin-left: calc(5em + #{$pos});
    animation: line-wrap $anim-dur $timing infinite;
  }
  
  .line {
    height: 0.6em;
    width: 0.22em;
    border-radius: 0.22em;
    background: $c6;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -0.3em;
    margin-left: -0.1em;
    animation: lines $anim-dur $timing infinite;
  }
  
  .line:first-child {
    transform: rotate(45deg) translate(0.4em) rotate(-90deg);
    animation: line1 $anim-dur $timing infinite
  }
  
  .line:nth-child(2) {
    transform: rotate(90deg) translate(0.4em) rotate(-90deg);
    animation: line2 $anim-dur $timing infinite
  }
  
  .line:nth-child(3) {
    transform: rotate(135deg) translate(0.4em) rotate(-90deg);
    animation: line3 $anim-dur $timing infinite
  }
  
  .line:nth-child(4) {
    transform: rotate(180deg) translate(0.4em) rotate(-90deg);
    animation: line4 $anim-dur $timing infinite
  }
  
  .line:nth-child(5) {
    transform: rotate(360deg) translate(0.4em) rotate(-90deg);
    animation: line5 $anim-dur $timing infinite
  }
  
  .line:nth-child(6) {
    transform: rotate(270deg) translate(0.4em) rotate(-90deg);
    animation: line6 $anim-dur $timing infinite
  }
  
  .line:nth-child(7) {
    transform: rotate(225deg) translate(0.4em) rotate(-90deg);
    animation: line7 $anim-dur $timing infinite
  }
  
  .line:nth-child(8) {
    transform: rotate(315deg) translate(0.4em) rotate(-90deg);
    animation: line8 $anim-dur $timing infinite
  }
  
  .circlThird {
    margin-left: calc(10em + #{$pos});
    width: 3em;
    height: 3em;
    background: $c3;
    animation: circle-third $anim-dur $timing infinite
  }
  
  .circleGreenCenter {
    width: 3em;
    height: 3em;
    margin-left: calc(10em + #{$pos});
    background: $c4;
    transform: scale(0.3);
    animation: green-center $anim-dur $timing infinite;
  }
  
  .circleRedCenter {
    width: 3em;
    height: 3em;
    margin-left: calc(10em + #{$pos});
    background: $c2;
    transform: scale(0.3);
    animation: red-center $anim-dur $timing infinite;
  }
  
  .greenArc {
    margin-left: calc(10em + #{$pos});
    width: 1.3em;
    height: 1.3em;
    border: 0.5em solid $c4;
    border-right-color: transparent;
    border-bottom-color: transparent;
    animation: green-arc $anim-dur $timing infinite;
    opacity: 0;
  }
  
  .redArc {
    margin-left: calc(10em + #{$pos});
    width: 2em;
    height: 2em;
    border: 0.3em solid $c2;
    border-right-color: transparent;
    border-bottom-color: transparent;
    animation: red-arc $anim-dur $timing infinite;
    opacity: 0;
  }
  
  .circleFourth {
    margin-left: calc(15em + #{$pos});
    width: 3em;
    height: 3em;
    background: $c4;
    animation: circle-fourth $anim-dur $timing infinite
  }
  
  .redArc2 {
    margin-left: calc(15em + #{$pos});
    width: 3em;
    height: 3em;
    background: $c2;
    animation: red-arc2 $anim-dur $timing infinite;
    opacity: 0;
    transform: scale(0.25)
  }
  
  .yellowBg2 {
    margin-left: calc(15em + #{$pos});
    width: 1em;
    height: 1em;
    border: 1em solid $c3;
    animation: yellow-bg2 $anim-dur $timing infinite;
    transform: scale(0.3);
    opacity: 0
  }
  
  .blueRing {
    margin-left: calc(15em + #{$pos});
    width: 3em;
    height: 3em;
    background: $c1;
    animation: blue-ring $anim-dur $timing infinite;
    transform: scale(0.3);
  }
  
  .redRing2 {
    margin-left: calc(15em + #{$pos});
    width: 3em;
    height: 3em;
    background: $c2;
    animation: red-ring2 $anim-dur $timing infinite;
    transform: scale(0.3);
  }
  
  .greenCenter {
    margin-left: calc(15em + #{$pos});
    width: 3em;
    height: 3em;
    background: $c4;
    animation: green-center $anim-dur $timing infinite;
    transform: scale(0);
  }
  
  .yellowCenter2 {
    margin-left: calc(15em + #{$pos});
    width: 3em;
    height: 3em;
    background: $c3;
    animation: yellow-center2 $anim-dur $timing infinite;
    transform: scale(0);
  }
  
  @keyframes scale {
    0% {
      transform: scale(1,1)
    }
  
    5% {
      transform: scale(1,1);
      background: $c1
    }
    10% {
      background: $c3;
    }
    15% {
      transform: scale(0.4,0.4);
    }
    50% {
      transform: scale(0.4,0.4);
      background: $c3
    }
    55% {
      background: $c1
    }
    65% {
      transform: scale(0.8, 0.8);
    }
    
    80% {
      transform: scale(0.8,0.8);
    }
    
    95% {
      transform: scale(1,1)
    }
    100% {
      transform: scale(1,1)
    }
  }
  
  @keyframes scale-out {
    0% {
      transform: scale(1,1);
      opacity: 0
    }
    2.5% {
      opacity: 0
    }
    
    5% {
      opacity: 1;
      transform: scale(1,1)
    }
    15% {
      transform: scale(0.4,0.4);
    }
    20% {
      transform: scale(0,0)
    }
    95% {
      opacity: 1;
      transform: scale(0,0);
    }
    100% {
      opacity: 1;
      transform: scale(0,0);
    }
  }
  
  @keyframes rotate {
    60% {
      opacity: 0;
      transform: rotate(180deg)
    }
    65% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
      transform: rotate(600deg);
    }
    
    95% {
      opacity: 0;
      transform: rotate(540deg);
    }
    100% {
      opacity: 0;
      transform: rotate(540deg);
    }
  }
  
  @keyframes arc-rotate {
    15% {
      transform: rotate(0deg);
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    35% {
      transform: rotate(360deg)
    }
    
    45% {
      opacity: 1;
    }
    
    55% {
      transform: rotate(720deg);
      opacity: 0;
    }
    
    95% {
      transform: rotate(720deg);
      opacity: 0;
    }
    100% {
      transform: rotate(720deg);
      opacity: 0;
    }
  }
  
  @keyframes yellow-fill {
  
    14% {
      opacity: 0;
    }
    15% {
      opacity: 1;
      width: 0.5em;
      height: 0.5em;
      border-radius: 0.5em;
    }
    25% {
      width: 3em;
      height: 3em;
      border-radius: 3em;
    }
    26% {
      width: 3em;
      height: 3em;
      border-radius: 3em;
      transform: translateX(0em);
    }
    35% {
      width: 8em;
      border-radius: 8em;
      transform: translateX(2.5em);
    }
    45% {
      width: 3em;
      border-radius: 3em;
      height: 3em;
      transform: translateX(5em);
    }
    55% {
      width: 0.85em;
      height: 0.85em;
      border-radius: 0.85em;
      opacity: 1;
      transform: translateX(5em);
    }
    62% {
      transform: translate(6.2em, 1.3em) rotate(0deg);
      transform-origin: 1.75em;
    }
    74% {
      transform: translate(6.2em, 1.3em) rotate(180deg);
    }
    
    81% {
      transform: translate(7.4em, 0em) rotate(180deg);
    }
    
    85% {
      background: $c3;
    }
  
    100% {
      background: $c4;
      width: 0.9em;
      height: 0.9em;
      border-radius: 0.9em;
      opacity: 1;
      transform-origin: 1.75em;
      transform: translate(7.4em, 0em) rotate(180deg);
    }
  }
  
  @keyframes circle-red-scale {
    0% {
      border: 0em solid $c2;
    }
    5% {
      transform: scale(0.6, 0.6);
      background: $c2;
    }
  
    10% {
      width: 0;
      height: 0;
      border: 1em solid $c2;
    }
    
    20% {
      background: transparent;
      width: 3em;
      height: 3em;
    }
    
    35% {
      background: $c2;
      width: 3em;
      height: 3em;
      border: 1em solid $c2;
    }
    
    50% {
      width: 1em;
      height: 1em;
      border: 0.5em solid $c2;
    }
    
    80% {
      width: 1em;
      height: 1em;
      border: 0.5em solid $c2;
      transform: scale(0.6, 0.6);
    }
    95% {
      background: $c2;
      width: 3em;
      height: 3em;
      border: 0em solid $c2;
      transform: scale(1, 1);
    }
    100% {
      background: $c2;
      width: 3em;
      height: 3em;
      border: 0em solid $c2;
      transform: scale(1, 1);
    }
  }
  
  @keyframes yellow-center {
    25% {
      opacity: 0
    }
    35% {
      opacity: 1
    }
    85% {
      opacity: 1
    }
    90% {
      opacity: 0
    }
    100% {
      opacity: 0
    }
  }
  
  @keyframes yellow-bg {
    45% {
      transform: scale(1,1)
    }
    55% {
      transform: scale(1.8,1.8)
    }
    80% {
      transform: scale(1.8,1.8)
    }
    90% {
      transform: scale(1,1)
    }
    100% {
      transform: scale(1,1)
    }
  }
  
  @keyframes line-wrap {
    55% {
      transform: rotate(0deg)
    }
    80% {
      transform: rotate(-45deg)
    }
  
    100% {
      transform: rotate(-45deg)
    }
  }
  
  @keyframes line1 {
    0% {
      opacity: 0
    }
    44% {
      opacity: 0
    }
    45% {
      opacity: 1;
      transform: rotate(45deg) translate(0.4em) rotate(-90deg)
    }
    55% {
      transform: rotate(45deg) translate(1.35em) rotate(-90deg)
    }
    60% {
      height: 0.6em;
      margin-top: -0.3em;
    }
    70% {
      height: 0.2em;
      margin-top: -0.1em;
    }
    80% {
      height: 0.2em;
      margin-top: -0.1em;
      transform: rotate(45deg) translate(1.35em) rotate(-90deg)
    }
    85% {
      height: 0.6em;
      margin-top: -0.3em;
    }
    90% {
      transform: rotate(45deg) translate(0.2em) rotate(-90deg)
    }
    100% {
      transform: rotate(45deg) translate(0.2em) rotate(-90deg)
    }
  }
  
  @keyframes line2 {
    0% {
      opacity: 0
    }
    44% {
      opacity: 0
    }
    45% {
      opacity: 1;
      transform: rotate(90deg) translate(0.4em) rotate(-90deg)
    }
    55% {
      transform: rotate(90deg) translate(1.35em) rotate(-90deg)
    }
    60% {
      height: 0.6em;
      margin-top: -0.3em;
    }
    70% {
      height: 0.2em;
      margin-top: -0.1em;
    }
    80% {
      height: 0.2em;
      margin-top: -0.1em;
      transform: rotate(90deg) translate(1.35em) rotate(-90deg)
    }
    85% {
      height: 0.6em;
      margin-top: -0.3em;
    }
    90% {
      transform: rotate(90deg) translate(0.2em) rotate(-90deg)
    }
    100% {
      transform: rotate(90deg) translate(0.2em) rotate(-90deg)
    }
  }
  
  @keyframes line3 {
    0% {
      opacity: 0
    }
    44% {
      opacity: 0
    }
    45% {
      opacity: 1;
      transform: rotate(135deg) translate(0.4em) rotate(-90deg)
    }
    55% {
      transform: rotate(135deg) translate(1.35em) rotate(-90deg)
    }
    60% {
      height: 0.6em;
      margin-top: -0.3em;
    }
    70% {
      height: 0.2em;
      margin-top: -0.1em;
    }
    80% {
      height: 0.2em;
      margin-top: -0.1em;
      transform: rotate(135deg) translate(1.35em) rotate(-90deg)
    }
    85% {
      height: 0.6em;
      margin-top: -0.3em;
    }
    90% {
      transform: rotate(135deg) translate(0.2em) rotate(-90deg)
    }
    100% {
      transform: rotate(135deg) translate(0.2em) rotate(-90deg)
    }
  }
  
  @keyframes line4 {
    0% {
      opacity: 0
    }
    44% {
      opacity: 0
    }
    45% {
      opacity: 1;
      transform: rotate(180deg) translate(0.4em) rotate(-90deg)
    }
    55% {
      transform: rotate(180deg) translate(1.35em) rotate(-90deg)
    }
    60% {
      height: 0.6em;
      margin-top: -0.3em;
    }
    70% {
      height: 0.2em;
      margin-top: -0.1em;
    }
    80% {
      height: 0.2em;
      margin-top: -0.1em;
      transform: rotate(180deg) translate(1.35em) rotate(-90deg)
    }
    85% {
      height: 0.6em;
      margin-top: -0.3em;
    }
    90% {
      transform: rotate(180deg) translate(0.2em) rotate(-90deg)
    }
    100% {
      transform: rotate(180deg) translate(0.2em) rotate(-90deg)
    }
  }
  
  @keyframes line5 {
    0% {
      opacity: 0
    }
    44% {
      opacity: 0
    }
    45% {
      opacity: 1;
      transform: rotate(360deg) translate(0.4em) rotate(-90deg)
    }
    55% {
      transform: rotate(360deg) translate(1.35em) rotate(-90deg)
    }
    60% {
      height: 0.6em;
      margin-top: -0.3em;
    }
    70% {
      height: 0.2em;
      margin-top: -0.1em;
    }
    80% {
      height: 0.2em;
      margin-top: -0.1em;
      transform: rotate(360deg) translate(1.35em) rotate(-90deg)
    }
    85% {
      height: 0.6em;
      margin-top: -0.3em;
    }
    90% {
      transform: rotate(360deg) translate(0.2em) rotate(-90deg)
    }
    100% {
      transform: rotate(360deg) translate(0.2em) rotate(-90deg)
    }
  }
  
  @keyframes line6 {
    0% {
      opacity: 0
    }
    44% {
      opacity: 0
    }
    45% {
      opacity: 1;
      transform: rotate(270deg) translate(0.4em) rotate(-90deg)
    }
    55% {
      transform: rotate(270deg) translate(1.35em) rotate(-90deg)
    }
    60% {
      height: 0.6em;
      margin-top: -0.3em;
    }
    70% {
      height: 0.2em;
      margin-top: -0.1em;
    }
    80% {
      height: 0.2em;
      margin-top: -0.1em;
      transform: rotate(270deg) translate(1.35em) rotate(-90deg)
    }
    85% {
      height: 0.6em;
      margin-top: -0.3em;
    }
    90% {
      transform: rotate(270deg) translate(0.2em) rotate(-90deg)
    }
    100% {
      transform: rotate(270deg) translate(0.2em) rotate(-90deg)
    }
  }
  
  @keyframes line7 {
    0% {
      opacity: 0
    }
    44% {
      opacity: 0
    }
    45% {
      opacity: 1;
      transform: rotate(225deg) translate(0.4em) rotate(-90deg)
    }
    55% {
      transform: rotate(225deg) translate(1.35em) rotate(-90deg)
    }
    60% {
      height: 0.6em;
      margin-top: -0.3em;
    }
    70% {
      height: 0.2em;
      margin-top: -0.1em;
    }
    80% {
      height: 0.2em;
      margin-top: -0.1em;
      transform: rotate(225deg) translate(1.35em) rotate(-90deg)
    }
    85% {
      height: 0.6em;
      margin-top: -0.3em;
    }
    90% {
      transform: rotate(225deg) translate(0.2em) rotate(-90deg)
    }
    100% {
      transform: rotate(225deg) translate(0.2em) rotate(-90deg)
    }
  }
  
  @keyframes line8 {
    0% {
      opacity: 0
    }
    44% {
      opacity: 0
    }
    45% {
      opacity: 1;
      transform: rotate(315deg) translate(0.4em) rotate(-90deg)
    }
    55% {
      transform: rotate(315deg) translate(1.35em) rotate(-90deg)
    }
    60% {
      height: 0.6em;
      margin-top: -0.3em;
    }
    70% {
      height: 0.2em;
      margin-top: -0.1em;
    }
    80% {
      height: 0.2em;
      margin-top: -0.1em;
      transform: rotate(315deg) translate(1.35em) rotate(-90deg)
    }
    85% {
      height: 0.6em;
      margin-top: -0.3em;
    }
    90% {
      transform: rotate(315deg) translate(0.2em) rotate(-90deg)
    }
    100% {
      transform: rotate(315deg) translate(0.2em) rotate(-90deg)
    }
  }
  
  @keyframes circle-third {
    0% {
      border: 0em solid $c3;
    }
    5% {
      transform: scale(0.6, 0.6);
      background: $c3;
    }
  
    10% {
      width: 0;
      height: 0;
      border: 1em solid $c3;
    }
    
    20% {
      background: transparent;
      width: 2.2em;
      height: 2.2em;
      border: 1em solid $c4;
    }
    20% {
      opacity: 1
    }
    35% {
      opacity: 0
    }
    100% {
      transform: scale(0.6, 0.6);
      background: transparent;
      width: 2.2em;
      height: 2.2em;
      border: 0;
    }
    
    
  }
  
  
  @keyframes red-center {
    0% {
      border: 0em solid $c2;
      opacity: 0
    }
    14% {
      opacity: 0;
    }
  
    15% {
      opacity: 1;
      transform: scale(0.2);
      border: 0em solid $c2;
      background: $c2;
    }
    30% {
      background: transparent;
      transform: scale(0.4);
      border: 2.2em solid $c2
    }
    44% {
      background: transparent;
      transform: scale(0.4);
      border: 2.2em solid $c2;
      opacity: 1;
    }
    45% {
      background: $c4;
      transform: scale(0.4);
      border: 0em solid $c2;
      opacity: 0;
    }
    
    60% {
      opacity: 0;
    }
    
    70% {
      opacity: 1;
    }
    
    85% {
      transform: scale(0.4);
      background: $c4
    }
  
    100% {
      opacity: 1;
      border: 0;
      transform: scale(1);
      background: $c3;
    }
  }
  
  @keyframes green-arc {
    
   25% {
      transform: rotate(0deg);
    } 
   49% {
      
      opacity: 0;
    }
    
    50% {
      opacity: 1;
    }
    
    55% {
      width: 1.3em;
      height: 1.3em;
    }
    
    70% {
      width: 0.2em;
      height: 0.2em;
      opacity: 1;
    }
    75% {
      opacity: 0;
    }
    90% {
      transform: rotate(-720deg);
    }
    
    100% {
      width: 0.2em;
      height: 0.2em;
      transform: rotate(-720deg);
      opacity: 0;
    }
  }
  
  @keyframes red-arc {
    
    45% {
      transform: rotate(0deg)
    }
    
    60% {
      
      opacity: 0;
    }
    
    65% {
      opacity: 1;
    }
    
    75% {
      border-bottom-color: transparent;
      border-right-color: transparent;
    }
  
    80% {
      width: 2em;
      height: 2em;
      transform: rotate(600deg);
      border-bottom-color: $c2;
      border-right-color: $c2;
    }
    
    90% {
      width: 1em;
      height: 1em;
    }
    
    100% {
      width: 1em;
      height: 1em;
      opacity: 1;
      transform: rotate(600deg);
      border-bottom-color: $c2;
      border-right-color: $c2;
    }
  }
  
  @keyframes circle-fourth {
    10% {
      width: 3em;
      height: 3em;
      background: $c4;
      border: 0em solid $c4;
      transform: scale(1)
    }
    
    15% {
      background: $c4;
    }
    
    20% {
      background: transparent;
      width: 1em;
      height: 1em;
      border: 1em solid $c4;
      transform: scale(1)
    }
    
    30% {
      transform: scale(0.3);
      opacity: 1;
      background: transparent;
      width: 1em;
      height: 1em;
      border: 1em solid $c4;
    }
    31% {
      opacity: 0;
      background: $c4;
      width: 3em;
      height: 3em;
      border: 0em solid $c4;
      transform: scale(0);
    }
    
    100% {
      background: $c4;
      width: 3em;
      height: 3em;
      border: 0em solid $c4;
      transform: scale(0);
      opacity: 0
    }
  }
  
  @keyframes red-arc2 {
    15% {
      opacity: 0;
      transform: scale(0.25)
    }
    20% {
      opacity: 1;
      width: 3em;
      height: 3em;
      background: $c2;
      border: 0em solid $c2
    }
    30% {
      opacity: 1;
      transform: scale(0.8) rotate(0deg);
      width: 1.2em;
      height: 1.2em;
      border: 0.8em solid $c2;
      background: transparent;
      border-bottom-color: $c2;
      border-right-color: $c2;
    }
    
    35% {
      border-bottom-color: transparent;
    }
    
    55% {
      opacity: 1
    }
    60% {
      opacity: 0
    }
    65% {
      transform: scale(0.5) rotate(600deg)
    }
    100% {
      opacity: 0;
      transform: scale(0.5) rotate(600deg);
      width: 1.2em;
      height: 1.2em;
      border: 0.8em solid $c2;
      background: transparent;
      border-bottom-color: transparent;
    }
  }
  
  @keyframes yellow-bg2 {
    40% {
      opacity: 0;
      transform: scale(0.3)
    }
    55% {
      opacity: 1;
      transform: scale(1) rotate(0deg);
    }
    
    57% {
      border-bottom-color: $c3;
      border-right-color: $c3;
      width: 1em;
      height: 1em;
      border: 1em solid $c3
    }
    
    62% {
      width: 1.5em;
      height: 1.5em;
      border: 0.5em solid $c3;
      border-bottom-color: transparent;
      border-right-color: transparent;
    }
    
    65% {
      transform: scale(1) rotate(120deg);
    }
   
    
    75% {
      transform: scale(1) rotate(240deg);
      opacity: 1;
    }
    
    85% {
      opacity: 1
    }
    86% {
      opacity: 0
    }
    
    
    95% {
      transform: scale(1) rotate(480deg);
    }
    
    100% {
      opacity: 0;
      width: 1.5em;
      height: 1.5em;
      border: 0.5em solid $c3;
      transform: scale(1) rotate(480deg);
      border-bottom-color: transparent;
      border-right-color: transparent;
    }
  }
  
  @keyframes blue-ring {
    0% {
      transform: scale(0)
    }
    20% {
      transform: scale(0);
      width: 3em;
      height: 3em;
      background: $c1;
      border: 0em solid $c1;
    }
  
    35% {
      width: 1em;
      height: 1em;
      background: transparent;
      border: 1em solid $c1;
      transform: scale(1)
    }
    
    55% {
      opacity: 1;
      transform: scale(1) rotate(10deg);
    }
    
    57% {
      border-bottom-color: $c1;
      border-right-color: $c1;
      width: 1em;
      height: 1em;
      border: 1em solid $c1
    }
    
    62% {
      width: 1.5em;
      height: 1.5em;
      border: 0.5em solid $c1;
      border-bottom-color: transparent;
      border-right-color: transparent;
    }
    
    75% {
      transform: scale(1) rotate(220deg);
      opacity: 1
    }
    
    85% {
      opacity: 1
    }
    86% {
      opacity: 0
    }
    
    95% {
      transform: scale(1) rotate(460deg);
    }
     
  
    100% {
      opacity: 0;
      width: 1.5em;
      height: 1.5em;
      border: 0.5em solid $c1;
      background: transparent;
      transform: scale(1) rotate(460deg);
      border-bottom-color: transparent;
      border-right-color: transparent;
    }
  }
  
  @keyframes red-ring2 {
    0% {
      transform: scale(0)
    }
    30% {
      transform: scale(0);
      width: 3em;
      height: 3em;
      background: $c2;
      border: 0em solid $c2;
    }
  
    45% {
      width: 1em;
      height: 1em;
      background: transparent;
      border: 1em solid $c2;
      transform: scale(1)
    }
    54% {
      opacity: 1
    }
    55% {
      opacity: 0
    }
    100% {
      width: 1em;
      height: 1em;
      background: transparent;
      border: 1em solid $c2;
      transform: scale(1);
      opacity: 0
    }
  }
  
  @keyframes green-center {
    55% {
      transform: scale(0)
    }
    75% {
      transform: scale(0.5);
      width: 3em;
      height: 3em;
      border: 0em solid $c4;
      background: $c4;
    }
    
    
    85% {
      transform: scale(1);
      width: 1em;
      height: 1em;
      border: 1em solid $c4;
      background: transparent;
      
    }
    
    86% {
      transform: scale(1)
    }
    
    90% {
      transform: scale(0.3);
    }
    100% {
      transform: scale(0.3);
      width: 1em;
      height: 1em;
      border: 1em solid $c4;
      background: transparent;
    }
  }
  
  @keyframes yellow-center2 {
    81% {
      transform: scale(0)
    }
    85% {
      transform: scale(0.3);
      background: $c3;
    }
    100% {
      transform: scale(1);
      background: $c4;
    }
  }